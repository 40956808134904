




















































































































































































































































































import { Component } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { listMixin } from "@/mixins/listMixin";
import { quanxianMixin } from "@/mixins/quanxianMixin";
import { GetRenwuList } from "@/request/mark";
import MainHeader from "@/components/main-header/main-header.vue";
import SearchFilter from "@/components/biaozhu/search-filter.vue";
import Jingdu from "@/components/biaozhu/jingdu.vue";
@Component({
  components: {
    MainHeader,
    SearchFilter,
    Jingdu,
  },
})
export default class Name extends mixins(listMixin, quanxianMixin) {
  private data: any[] = [];
  private pageSetting: any = {};
  private ifShowJindu: any = false;
  private jingduId: any = "";
  private changeSearch(d: any) {
    d.list_type = this.filters.list_type;
    this.filters = d;
    this.getList();
  }
  private openjindu(item: any) {
    if (!this.getIsShowQuanxian("标注结果库标注进度")) {
      return;
    }
    this.jingduId = item.id;
    this.ifShowJindu = true;
  }
  private changeListType(type: any) {
    this.filters.list_type = type;
    localStorage.setItem("biaozhujieguoFilter", JSON.stringify(this.filters));
    this.$forceUpdate();
  }
  /**
   * @description 查看
   */
  private goRead(item: any) {
    if (!this.getIsShowQuanxian("标注结果库查看")) {
      return;
    }
    this.$store.commit("updateIfCompose", false);
    this.$router.push({
      path: "/main/mark/biaozhu/detail",
      query: {
        id: item.id,
        kind: "标注结果库",
        type: "read",
      },
    });
  }
  /**
   * @description 获取用户列表
   */
  getList() {
    const params: any = {
      params: {
        search: this.filters.search,
        user_id: this.filters["标注员"],
        category: this.filters["分类"],
        order_field: "更新时间",
        order_value: this.filters.sort_value,
        current_page: this.filters.current_page,
        page_num: this.filters.page_count,
        kind: "标注结果库",
      },
    };
    localStorage.setItem("biaozhujieguoFilter", JSON.stringify(this.filters));
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    GetRenwuList(this, params, loading)
      .then((data: any) => {
        loading.close();
        this.data = data.data;
        this.filters.total_count = data.total_count;
        this.filters.total_page = data.total_page;
        if (this.data.length === 0) {
          this.ifShowEmpty = true;
        } else {
          this.ifShowEmpty = false;
        }
      })
      .catch(() => {
        loading.close();
      });
  }
  /**
   * @description 初始化
   */
  mounted() {
    this.filters.list_type = "text";
    this.filters["分类"] = "";
    this.filters["标注员"] = "";
    this.filters.page_count = 10;
    const d = localStorage.getItem("biaozhujieguoFilter") || "";
    if (d) {
      this.filters = JSON.parse(d);
    }
    if (!this.filters.list_type) {
      this.filters.list_type = "text";
    }
    this.getList();
  }
}
